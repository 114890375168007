.button {
  padding: 1rem 3rem;
  font-size: 1.5rem;
  border-radius: 3rem;
  border: 1.5px solid $color-primary;
  background-color: transparent;
  color: $color-primary;
  font-weight: 600;
  outline: none;
  background-color: white;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: $color-primary;
    color: white;
  }

  &.active {
    background-color: $color-primary;
    color: white;
  }

  &.secondary {
    display: flex;
    align-items: center;
    border-color: $color-secondary;
    color: $color-secondary;
    background-color: white;
    font-weight: 400;
    font-size: 1.8rem;
    padding: 1.5rem 3rem;

    &:hover {
      border-color: $color-grey-1;
      color: $color-grey-1;
    }

    &-big {
      display: flex;
      align-items: center;
      border-color: $color-secondary;
      color: $color-secondary;
      background-color: white;
      font-weight: 400;
      font-size: 2.5rem;
      padding: 1.75rem 4rem;
      border-radius: 10rem;

      &:hover {
        border-color: $color-grey-1;
        color: $color-grey-1;
      }
    }
  }

  &.light {
    border-color: $color-grey-1;
    color: $color-grey-1;
    padding: 0.75rem 2.5rem;

    &:hover {
      border-color: $color-secondary;
      color: $color-secondary;
      background-color: white;
    }
  }

  &.choose {
    padding: 1rem;
    border: 1px solid $color-grey-1;
    border-radius: 0.4rem;
    color: $color-grey-1;

    &:hover {
      background-color: $color-grey-1;
      color: white;
    }

    &:active {
      background-color: $color-grey-1;
      color: white;
    }
  }
}
