.orderdetails {
    width: 100%;
    display: flex;
    justify-content: center;

    @media (min-width: $dim-breakpoint-md-lg) {
        min-height: 100%;
    }

    &__center {
        width: 100%;
        height: 100%;
        max-width: $dim-breakpoint-lg;
        display: flex;
        flex-direction: column;

        @media (min-width: $dim-breakpoint-md-lg) {
            justify-content: center;
        }
    }

    &__summary {
        width: 100%;
    }

    &-columns {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 2rem;

        &-wrapper {
            display: flex;
            flex-direction:row;
            width: 100%;
            padding: 2rem;
            background-color: white;
            box-shadow: $box-shadow-1;
            height: 100%;

            @media (min-width: $dim-breakpoint-md-lg) {
                flex-direction: row;
                padding: 0;
            }
        }

        &__column {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            @media (min-width: $dim-breakpoint-md-lg) {
                padding: 2rem 0;
            }

            &-buttons {
                display: flex;
                padding: 1rem 0;

                @media(min-width: $dim-breakpoint-md-lg) {
                    padding: 1rem 2rem;
                }

                &-button {
                }
            }

            &-cell {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 1rem 0;

                &-small {
                    display: flex;
                    flex-direction: column;
                    flex: 0 0 30%;
                    padding-bottom: 1rem;
                    @media (min-width: $dim-breakpoint-sm) {
                        padding-bottom: 0rem;
                        padding-right: 1rem;
                    }
                }

                &-big {
                    display: flex;
                    flex-direction: column;
                    flex: 1 0 50%;
                    @media (min-width: $dim-breakpoint-sm) {
                        padding-left: 1rem;
                    }
                }

                &--double {
                    display: flex;
                    flex-direction: column;

                    @media(min-width: $dim-breakpoint-sm) {
                        flex-direction: row;
                    }

                    @media (min-width: $dim-breakpoint-md-lg) {
                        padding: 1rem 2rem;
                    }
                }

                @media (min-width: $dim-breakpoint-md-lg) {
                    padding: 1rem 2rem;
                }

                &__header {
                    font-size: 1.7rem;
                    padding-bottom: 1rem;
                    font-weight: 600;
                }

                &__content {
                    font-size: 1.7rem;
                }

                &-input {
                    border: 1px solid $color-grey-1;
                    border-radius: 0.75rem;
                    padding: 0.75rem 1.5rem;
                    outline: none;
                    font-size: 1.5rem;
                }
            }
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0rem 2rem 4rem;

        @media (min-width: $dim-breakpoint-md-lg) {
            flex-direction: row;
            justify-content: space-between;
        }

        &-button {
            padding: 1rem 0;
        }
    }
}
