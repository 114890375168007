.nav {
  position: fixed;
  display: flex;
  height: 8rem;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(black, 0.3);
  z-index: 6;

  &__center {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: $dim-breakpoint-lg;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $dim-breakpoint-md) {
    }

    &::before {
      content: "";
      position: absolute;
      height: 8rem;
      width: 100%;
      background-color: white;
      z-index: 2;
    }
  }

  &__logo {
    display: flex;
    cursor: pointer;
    height: 100%;
    align-items: center;
    z-index: 3;
    padding-left: 1rem;

    &-image {
      height: 6rem;

      @media (min-width: $dim-breakpoint-md-lg) {
        height: 8rem;
      }
    }
  }

  &__right {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    @media (min-width: $dim-breakpoint-md) {
      display: none;
    }

    &-button {
      @include ff-icon;
      font-size: 3.2rem;
      padding: 2rem;
    }
  }

  &__links {
    display: flex;
    flex: 0 0 100%;
    padding-top: 1rem;
    font-size: 1.6rem;
    background-color: white;
    transform: translate(0, -180%);
    z-index: 1;
    transition: all 0.2s ease-in-out;

    @media (min-width: $dim-breakpoint-md) {
      padding: 0;
      transform: translate(0, 0);
      z-index: 3;
      flex: 1 0 50%;
      justify-content: flex-end;
    }

    &-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      @media (min-width: $dim-breakpoint-md) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-right: 1rem;
      }
    }
  }

  &__link {
    font-size: 2rem;
    color: $color-grey-1;
    padding: 1.25rem 0;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: $color-primary;
    }

    &.active {
      color: $color-primary;
    }

    @media (min-width: $dim-breakpoint-md) {
      padding: 0 1rem;
    }
  }

  &.is-open {
    .nav {
      &__links {
        padding: 1rem 0;
        display: flex;
        border-bottom: 1px solid $color-secondary;
        transform: translate(0, 0);
        transition: all 0.2s ease-in-out;

        @media (min-width: $dim-breakpoint-md) {
          border-bottom: none;
        }
      }
    }
  }
}

.przycisk {
  @include ff-icon;
  position: fixed;
  bottom: 5rem;
  right: 1rem;
  font-size: 6rem;
  color: white;
  background-color: $color-primary;
  border-radius: 5rem;
}
