.about {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__header {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem;
    background-color: $color-primary;
    color: white;
    font-size: 3.5rem;
    font-weight: 600;
    text-align: center;

    @media (min-width: $dim-breakpoint-sm) {
      font-size: 3rem;
    }
  }

  &__center {
    max-width: $dim-breakpoint-lg;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    &-bottomline {
      display: flex;
      height: 1px;
      background-color: $color-secondary;
      margin-bottom: 2rem;
      width: 60%;
      text-align: center;
    }
  }

  &-columns {
    display: flex;
    flex-direction: column;
    padding: 3rem 1rem 2rem;
    flex-wrap: wrap;

    @media (min-width: $dim-breakpoint-sm) {
      flex-direction: row;
      padding: 3rem 1rem;
    }

    &__cell {
      display: flex;
      width: 100%;
      padding: 1rem 1rem;

      @media (min-width: $dim-breakpoint-sm) {
        flex: 0 0 50%;
      }

      @media (min-width: $dim-breakpoint-md-lg) {
        flex: 0 0 1/3 * 100%;
        padding: 1rem 1.5rem;
      }

      @media (min-width: $dim-breakpoint-lg) {
      }

      &-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        background-color: white;
        color: $color-grey-1;
        box-shadow: $box-shadow-1;
      }

      &-photo {
        display: flex;
        justify-content: center;
        width: 1/4 * 100%;
        margin: 2rem 0 1rem;

        &-image {
          width: 100%;
          height: 100%;
        }
      }

      &-header {
        font-weight: 600;
        font-size: 2.25rem;
        padding: 0 0 2rem;
      }

      &-description {
        padding: 0 2rem 3rem;
        font-size: 1.65rem;
        line-height: 2.5rem;
        text-align: center;
      }
    }
  }

  &__split {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $dim-breakpoint-md) {
      flex-direction: row;
      padding: 0 1rem;
    }
  }

  &__left {
    display: flex;
    justify-content: center;
    max-width: 350px;
    padding-bottom: 2rem;

    &__photo {
      display: flex;
      width: 100%;
      padding: 2rem;

      &-image {
        width: 100%;
        height: 100%;
        box-shadow: $box-shadow-3;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    color: $color-grey-1;
    line-height: 2.5rem;

    @media (min-width: $dim-breakpoint-md) {
      display: flex;
      flex: 0 0 65%;
      padding-left: 4rem;
    }

    @media (min-width: $dim-breakpoint-lg) {
      padding-left: 6rem;
    }

    &-header {
      padding-bottom: 2rem;
      font-size: 3rem;
      line-height: 3.5rem;
      font-weight: 600;
      text-align: center;
    }

    &-text {
      font-size: 1.65rem;
    }

    &-list {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 1rem 0;
      padding-right: 0;
      list-style: none;
      font-size: 1.65rem;
      height: 100%;

      &__element {
        padding-bottom: 1rem;
        position: relative;
        padding-left: 2rem;

        &::before {
          content: "";
          position: absolute;
          border: 1px solid $color-primary;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          left: 0;
          top: 20%;
        }
      }
    }
  }
}
