.start {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 10rem;
  padding-bottom: 6rem;

  &__center {
    max-width: $dim-breakpoint-lg;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $dim-breakpoint-md) {
      flex-direction: row;
      padding: 0 1rem;
    }
  }

  &__left {
    display: flex;
    width: 100%;
    max-width: 350px;
    padding-bottom: 2rem;

    @media (min-width: $dim-breakpoint-md) {
      padding: 2rem 0 2rem 0rem;
    }

    &__photo {
      display: flex;
      padding: 1rem 2rem;

      &-image {
        width: 100%;
        height: 100%;
        box-shadow: $box-shadow-3;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    align-items: center;
    justify-content: center;

    @media (min-width: $dim-breakpoint-md) {
      display: flex;
      flex: 0 0 65%;
      padding-left: 4rem;
    }

    @media (min-width: $dim-breakpoint-lg) {
      padding-left: 6rem;
    }
  }

  &__header {
    width: 100%;
    font-size: 3rem;
    text-align: center;
    color: $color-primary;
    font-weight: bold;
    padding-bottom: 4rem;
  }

  &__text {
    width: 100%;
    padding-bottom: 4rem;
    font-size: 1.8rem;
    color: $color-grey-1;
    line-height: 2.5rem;

    @media (min-width: $dim-breakpoint-md-lg) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    &-icon {
      @include ff-icon;
      padding-left: 2rem;
    }
  }
}
