.authors {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $dim-breakpoint-lg;
    width: 100%;
    padding: 2rem 2rem;
  }

  &__wrapper {
    display: flex;
    background-color: white;
    flex-direction: column;
    padding: 2rem 3rem;
    margin-bottom: 4rem;
    line-height: 2.5rem;
    box-shadow: $box-shadow-1;
  }

  &__header {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    color: $color-primary;
    font-size: 3rem;
    font-weight: 600;
    width: 100%;

    @media (min-width: $dim-breakpoint-sm) {
      justify-content: flex-start;
    }
  }
  &__text {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1.65rem;
    color: $color-grey-1;
    padding-bottom: 3rem;

    @media (min-width: $dim-breakpoint-sm) {
      justify-content: flex-start;
      text-align: start;
      width: 80%;
    }
  }
  &__cursive {
    display: flex;
    justify-content: center;
    font-style: italic;
    font-size: 1.55rem;
    width: 100%;
    text-align: center;
    color: $color-grey-1;
    padding-bottom: 2rem;

    @media (min-width: $dim-breakpoint-sm) {
      justify-content: flex-end;
      text-align: end;
      width: 70%;
      margin-left: auto;
    }

    @media (min-width: $dim-breakpoint-md-lg) {
      width: 60%;
    }

    @media (min-width: $dim-breakpoint-lg) {
      width: 44%;
    }
  }

  &__author {
    font-size: 1.5rem;
    color: $color-primary;
    font-weight: 600;
    display: flex;
    justify-content: center;

    @media (min-width: $dim-breakpoint-sm) {
      justify-content: flex-end;
    }
  }
}
