.buynow {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10rem;

  @media (min-width: $dim-breakpoint-md) {
    min-height: 100%;
  }

  &__center {
    max-width: $dim-breakpoint-lg;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-columns {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 1rem;

    @media (min-width: $dim-breakpoint-sm) {
      flex-direction: row;
      align-items: stretch;
      padding: 0;
      margin: 0;
      margin-bottom: 2rem;
      border-radius: 1rem;
    }

    &__left {
      display: flex;
      padding-bottom: 2rem;

      @media (min-width: $dim-breakpoint-sm) {
        max-width: 150px;
        padding-bottom: 0;
      }

      &-photo {
        width: 100%;
        height: 100%;
        border-radius: 1rem 1rem 0 0;

        @media (min-width: $dim-breakpoint-sm) {
          border-radius: 1rem 0 0 1rem;
        }
      }
    }

    &__right {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      @media (min-width: $dim-breakpoint-md) {
        padding-left: 4rem;
      }

      &-wrapper {
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @media (min-width: $dim-breakpoint-md) {
          flex-direction: row;
          align-items: center;
        }
      }

      &-header {
        color: $color-primary;
        font-weight: 600;
        font-size: 2.5rem;
        padding-bottom: 2rem;

        @media (min-width: $dim-breakpoint-md) {
          padding-bottom: 0;
        }
      }
      &-table {
        display: flex;
        color: $color-grey-1;
        justify-content: space-between;
        @media (min-width: $dim-breakpoint-md) {
          flex: 0 0 60%;
          justify-content: flex-end;
        }

        &__cell {
          display: flex;
          flex-direction: column;
          align-items: space-between;

          @media (min-width: $dim-breakpoint-md) {
            padding: 0 2rem;
          }

          &-header {
            width: 100%;
            text-align: center;
            font-size: 2rem;
            padding-bottom: 1rem;
          }

          &-content {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;

            &__icon {
              &-right {
                margin-right: 0.65rem;
                margin-left: 0.2rem;
                @include ff-icon;
                font-size: 2.1rem;
                border-right: 1px solid $color-grey-1;
              }

              &-left {
                margin-left: 0.65rem;
                margin-right: 0.2rem;
                @include ff-icon;
                font-size: 2.1rem;
                border-left: 1px solid $color-grey-1;
              }
              // border: 1px solid $color-grey-1;
              // border-radius: 5rem;
            }

            &.add {
              border: 1px solid $color-grey-1;
              border-radius: 2rem;
            }
          }
        }
      }
    }
  }

  &-buttons {
    padding: 2rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $dim-breakpoint-sm) {
      flex-direction: row;
      justify-content: flex-end;
    }

    &__button {
      padding-bottom: 1rem;
      padding-right: 2rem;
    }
  }

  &-delivery {
    padding: 2rem;
    width: 100%;

    &-wrapper {
      background-color: white;
      border-radius: 1rem;
      padding: 2rem;
      display: flex;
      align-items: center;
      margin-left: auto;

      max-width: 350px;
    }

    &__icon {
      @include ff-icon;
      font-size: 3.5rem;
      padding-right: 1rem;
      color: $color-grey-1;
    }

    &__box {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &-header {
        font-size: 1.8rem;
        color: $color-grey-1;
      }

      &-content {
        font-size: 1.8rem;
        color: $color-grey-1;

        @media (min-width: $dim-breakpoint-md) {
          padding-right: 2rem;
        }
      }
    }
  }

  &-price {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 6rem;

    &__wrapper {
      font-size: 2.5rem;
      color: $color-grey-1;
      font-weight: 600;
    }
  }
}
