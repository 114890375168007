.summary {
    width: 100%;
    padding-top: 10rem;
    display: flex;
    justify-content: center;

    &__center {
        width: 100%;
        max-width: $dim-breakpoint-lg;
        display: flex;
        flex-direction: column;
    }

    &__header {
        font-size: 2.5rem;
        font-weight: 600;
        color: $color-grey-1;
        padding: 1rem 0 2rem;
    }

    &__order {
        display: flex;
        flex-direction: column;
        padding: 2rem;

        &-info {
            display: flex;
            flex-direction: column;
            background-color: white;
            box-shadow: $box-shadow-1;
            padding: 2rem;

            @media (min-width: $dim-breakpoint-md-lg) {
                flex-direction: column;
                align-items: stretch;
                justify-content: center;
            }

            &-wrapper {
                display: flex;
                flex-direction: column;

                @media (min-width: $dim-breakpoint-md-lg) {
                    justify-content: center;
                }
            }

            &__main {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-bottom: 2rem;

                &__header {
                    width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    text-align: end;
                    color: $color-primary;
                    font-weight: 600;
                    font-size: 2rem;
                    padding-bottom: 1rem;

                    @media (min-width: $dim-breakpoint-sm) {
                        text-align: center;
                        padding-left: 1rem;
                        margin-right: auto;
                        width: 100%;
                        justify-content: flex-start;
                        padding-bottom: 0;
                    }
                }
            }

            &__photo {
                display: flex;
                max-width: 125px;

                &-image {
                    width: 100%;
                    height: 100%;
                }
            }

            &__cells {
                @media (min-width: $dim-breakpoint-md-lg) {
                    justify-content: center;
                    flex: 0 0 50%;
                    margin-left: auto;

                }
            }

            &__cell {
                display: flex;
                flex-direction: row;
                padding: 1rem 2rem;
                justify-content: space-between;
                margin-bottom: 1rem;

                @media (min-width: $dim-breakpoint-sm) {
                    margin-left: auto;
                    display: flex;
                    max-width: 325px;
                    padding-right: 4rem;
                    width: 100%;
                    margin-bottom: 0;
                }
            }

            &__header {
                display: flex;
                align-items: center;
                color: $color-grey-1;
                font-weight: 600;
                font-size: 2rem;
                padding-bottom: 1rem;
            }

            &__content {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                text-align: end;
                color: $color-grey-1;
                font-size: 2rem;
                padding-bottom: 1rem;

                @media (min-width: $dim-breakpoint-md-lg) {
                    justify-content: center;
                    text-align: center;
                }
            }
        }
    }
}
